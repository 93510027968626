class ModalButton {
    // STATIC PART
    static _init() {
        ModalButton.color = {
            primary: "primary",
            secondary: "secondary",
            success: "success",
            danger: "danger",
            warning: "warning",
            info: "info",
            light: "light",
            dark: "dark",
        };

        ModalButton.variant = {
            default: "",
            outline: "outline",
            link: "link",
        };
    }

    static _auto_init() {
        if (!this._initialized) {
            this._init();
            this._initialized = true;
        }
    }

    static Create(text, variant, color, handler) {
        ModalButton._auto_init();
        const btn = new ModalButton(text);
        btn.setVariant(variant);
        btn.setColor(color);
        btn.setClickHandler(handler);
        return btn;
    }

    static CreateLink(text, handler) {
        ModalButton._auto_init();
        const btn = new ModalButton(text);
        btn.setVariant(ModalButton.variant.link);
        btn.setClickHandler(handler);
        return btn;
    }

    static DefaultCancel(action, text = "Abbrechen") {
        ModalButton._auto_init();
        return ModalButton.Create(
            text,
            ModalButton.variant.default,
            ModalButton.color.secondary,
            action
        );
    }

    static DefaultAccept(action, text) {
        ModalButton._auto_init();
        return ModalButton.Create(
            text,
            ModalButton.variant.default,
            ModalButton.color.primary,
            action
        );
    }

    // CLASS PART
    constructor(text = "") {
        ModalButton._auto_init();
        this.text = text;
        this.type = "button";
        this.variant = ModalButton.variant.default;
        this.color = ModalButton.color.primary;
        this.clickHandler = false;
        this.classes = [];
    }

    setType(type) {
        this.type = type;
    }

    setVariant(variant) {
        if (variant == ModalButton.variant.link) {
            this.setColor("");
        }
        this.variant = variant;
    }

    setColor(color) {
        if (this.variant == ModalButton.variant.link) {
            this.setVariant(ModalButton.variant.default);
        }
        this.color = color;
    }

    setClickHandler(clickHandler) {
        this.clickHandler = clickHandler;
    }

    addClass(cls) {
        this.classes.push(cls);
    }

    getClasses() {
        const classes = ["btn"];
        let styleClass = "btn";
        if (this.color) styleClass += "-" + this.color;
        if (this.variant) styleClass += "-" + this.variant;
        classes.push(styleClass);
        return [...classes, ...this.classes];
    }

    render() {
        const btn = document.createElement("button");
        btn.type = this.type;
        btn.classList.add(...this.getClasses());
        btn.innerText = this.text;
        if (this.clickHandler) btn.onclick = this.clickHandler;
        return btn;
    }
}

export default ModalButton;
