$(function () {
    $(".custom-file-input").each(function () {
        this.onchange = function () {
            if (this.files.length > 0) {
                const fileNameString = Array.prototype.map
                    .call(this.files, function (file) {
                        return file.name;
                    })
                    .join(", ");
                $(`[for="${this.id}"]`).text(fileNameString);
            }
        };
    });
});
