import axios from "axios";
import Modal from "../../modals/modal";
import CTableRows from "../rows/ctable-rows";
import ResourceAction from "./resource-action";

class DeleteResourceAction extends ResourceAction {
    static Enabled() {
        return CTableRows.GetSelectedRowsCount() === 1;
    }

    static Action(row = false) {
        let id = "";
        if (row) {
            id = $(row).data().resourceId;
        } else {
            id = CTableRows.GetSelectedRows().data().resourceId;
        }

        Modal.ConfirmPopup(
            "Delete?",
            "Do you want to delete this resource?",
            function () {
                const form = $("#resourceDeleteForm");
                form.prop(
                    "action",
                    $("#contentTable").data().resourcePath + "/" + id
                );
                form.trigger("submit");
            },
            "Delete",
            "Cancel"
        );
    }

    static Register() {
        $('[data-resource-action="delete"]').each(function () {
            this.onclick = function (clickEvent) {
                clickEvent.preventDefault();
                if (DeleteResourceAction.Enabled()) {
                    DeleteResourceAction.Action();
                }
            };
        });
    }
}

export default DeleteResourceAction;
