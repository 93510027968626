import ModalButton from "./modal-button";

class ModalContent {
    // Static part
    static CreateInputField(labelText, name, value = "", type = "text") {
        const formGroup = new ModalContent("div", {}, ["form-group"]);

        const label = new ModalContent("label", { for: `modal-input-${name}` });
        label.setInnerText(labelText);

        const input = new ModalContent(
            "input",
            {
                type,
                placeholder: labelText,
                name,
                id: `modal-input-${name}`,
                value,
            },
            ["form-control"]
        );

        formGroup.addContentNodes(label, input);

        return formGroup;
    }

    static CreateAutoCompleteInputField(labelText, name, value = "") {
        const formGroup = new ModalContent("div", {}, [
            "form-group",
            "autocomplete",
        ]);

        const label = new ModalContent("label", { for: `modal-input-${name}` });
        label.setInnerText(labelText);

        const input = new ModalContent(
            "input",
            {
                type: "text",
                placeholder: labelText,
                name,
                id: `modal-input-${name}`,
                value,
            },
            ["form-control"]
        );

        formGroup.addContentNodes(label, input);

        return formGroup;
    }

    static CreateSelectField(labelText, name, options) {
        const formGroup = new ModalContent("div", {}, ["form-group"]);

        const label = new ModalContent("label", {
            for: `modal-select-${name}`,
        });
        label.setInnerText(labelText);

        const input = new ModalContent(
            "select",
            { name: name, id: `modal-select-${name}` },
            ["form-control"]
        );

        options.forEach((option) => {
            const opt = new ModalContent("option", {
                value: option.value,
            });
            if (option.selected) opt.addAttributes({ selected: "" });
            opt.setInnerText(option.text);
            input.addContentNodes(opt);
        });

        formGroup.addContentNodes(label, input);

        return formGroup;
    }

    static CreateCheckboxField(labelText, name, checked = false) {
        const formGroup = new ModalContent("div", {}, [
            "custom-control",
            "custom-switch",
        ]);

        const input = new ModalContent(
            "input",
            { type: "checkbox", id: `modal-check-${name}` },
            ["custom-control-input"]
        );
        if (checked) input.addAttributes({ checked: "" });

        const label = new ModalContent(
            "label",
            { for: `modal-check-${name}` },
            ["custom-control-label"]
        );
        label.setInnerText(labelText);

        formGroup.addContentNodes(input, label);

        return formGroup;
    }

    // Class part
    constructor(type, attributes = {}, classes = []) {
        this.type = type;
        this.attributes = attributes;
        this.classes = classes;
        this.contentNodes = [];
        this.innerContentType = "";
        this.innerContent = "";
        this.listeners = {};
    }

    setType(type) {
        this.type = type;
    }

    setInnerText(text) {
        this.innerContentType = "text";
        this.innerContent = text;
    }

    setInnerHTML(html) {
        this.innerContentType = "html";
        this.innerContent = html;
    }

    addAttributes(attributes) {
        Object.keys(attributes).forEach((attribute) => {
            this.attributes[attribute] = attributes[attribute];
        });
    }

    addListener(action, callback) {
        this.listeners[action] = callback;
    }

    addClasses(...classes) {
        this.classes.push(...classes);
    }

    addContentNodes(...contentNodes) {
        this.contentNodes.push(
            ...contentNodes.map((node) => {
                if (node instanceof ModalContent || node instanceof ModalButton)
                    return node.render();
                return node;
            })
        );
    }

    render() {
        const el = document.createElement(this.type);
        Object.keys(this.attributes).forEach((attribute) =>
            el.setAttribute(attribute, this.attributes[attribute])
        );
        el.classList.add(...this.classes);

        if (this.innerContentType == "text") el.innerText = this.innerContent;
        else if (this.innerContentType == "html")
            el.innerHTML = this.innerContent;

        el.append(...this.contentNodes);

        Object.keys(this.listeners).forEach((action) => {
            el.addEventListener(action, this.listeners[action]);
        });

        return el;
    }
}

export default ModalContent;
