import axios from "axios";
import Modal from "./modals/modal";
import ModalButton from "./modals/modal-button";
import ModalContent from "./modals/modal-content";

$(function () {
    $(".media-selector[data-media-input]").each(function () {
        this.onclick = async function () {
            // show media selection modal
            const modal = new Modal("Media Library");
            modal.addClasses("media-library-popup");
            modal.setSize("xxl");
            modal.setScrollable(true);

            const mediaInput = $(this).data().mediaInput;

            // create content
            const mediaList = new ModalContent("div", {}, ["media-list"]);

            // load media files
            let response;
            try {
                response = await axios.get(
                    `/api/media?api_token=${_DHmpetXXCelO}`
                );
            } catch (err) {
                window.alert(
                    `Failed to load media files (${err.response.status})`
                );
                return;
            }

            response.data.forEach(function (mediaFile) {
                const mediaIcon = new ModalContent("div", {}, ["media-icon"]);
                mediaIcon.addAttributes({
                    "data-media-id": mediaFile.id,
                });

                const mediaIconWrapper = new ModalContent("div", {}, [
                    "media-icon-wrapper",
                ]);
                const mediaIconImage = new ModalContent(
                    "img",
                    { src: mediaFile.url },
                    ["media-icon-wrapper-image"]
                );

                mediaIcon.addListener("click", function () {
                    $(
                        `.media-library-popup .media-list .media-icon.selected`
                    ).each(function () {
                        $(this).removeClass("selected");
                    });
                    $(this).addClass("selected");
                });

                mediaIconWrapper.addContentNodes(mediaIconImage);
                mediaIcon.addContentNodes(mediaIconWrapper);
                mediaList.addContentNodes(mediaIcon);
            });

            // create buttons
            const acceptButton = ModalButton.DefaultAccept(
                selectImage.bind(this, mediaInput, modal),
                "Set image"
            );
            const cancelButton = ModalButton.DefaultCancel(
                () => modal.dispose(),
                "Cancel"
            );

            // add nodes
            modal.addContentNodes(mediaList);
            modal.addButtonNodes(cancelButton, acceptButton);

            modal.render(document.body);
        };
    });
});

function selectImage(mediaInputId, modal) {
    const selected = $(
        `.media-library-popup .media-list .media-icon.selected`
    ).first();

    const id = selected.data().mediaId;
    const src = selected.find(".media-icon-wrapper-image").attr("src");

    $(`#${mediaInputId}`).val(id);
    $(`.media-selector[data-media-input=${mediaInputId}]`)
        .find(".image-container img")
        .attr("src", src);

    modal.dispose();
}
