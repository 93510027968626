class ResourceAction {
    static Enabled() {
        return false;
    }

    static Handle() {}

    static Register() {}
}

export default ResourceAction;
