class OptionTypes {
    static get SYSTEM() {
        return 0;
    }

    static get CONTENT() {
        return 1;
    }

    static get ACTION() {
        return 2;
    }
}

// template result for no options
const item404 = {
    text: "No Results found",
    tags: [],
    action: {
        type: "function",
        target: "",
    },
};

// dom element identifiers
const inp_wrapper_id = "quick-search-container";
const inp_input_id = "quick-search-input";
const opt_wrapper_id = "qs-option-list";
const opt_item_class = "qs-option-list--item";

// refresh timeout settings
const refresh_timeout = 250;
var refresh_timeout_id = null;

// filtering options
const filter = {
    min_weight: 0.6,
    max_elements: 10,
    multiplier: {
        text: 1.0,
        tags: 1.6,
    },
};

// previous query string
var prevQuery = "";
var prevItems = [item404];

$(function () {
    // query update listener
    QSElements.inp_input.on("keydown", function () {
        // clear timeout if set
        if (refresh_timeout_id !== null) {
            clearTimeout(refresh_timeout_id);
            refresh_timeout_id = null;
        }

        // start refresh timeout
        refresh_timeout_id = setTimeout(() => {
            // update option list
            const query = $(this).val();
            hideOptions();
            if (query.length > 0) displayOptions(query);
        }, refresh_timeout);
    });

    // input element focus listener
    QSElements.inp_input.on("focus", function () {
        // display options if there is a query
        if ($(this).val().length > 0) {
            if (!optionsVisible()) {
                displayOptions($(this).val());
            }
        }
    });

    // global click listener
    $(document).on("click", function (clickEvent) {
        // check if target is wrapper
        if ($(clickEvent.target).attr("id") === inp_wrapper_id) return;

        // check if target is input
        if ($(clickEvent.target).attr("id") === inp_input_id) return;

        // check if target is option wrapper
        if ($(clickEvent.target).attr("id") === opt_wrapper_id) return;

        // check if target is option item
        if ($(clickEvent.target).hasClass(opt_item_class)) return;

        // hide options list and clear interval if present
        if (refresh_timeout_id !== null) clearTimeout(refresh_timeout_id);
        hideOptions();
    });

    // global key listener
    $(document).on("keydown", function (keyEvent) {
        if (keyEvent.ctrlKey && keyEvent.code === "KeyF") {
            keyEvent.preventDefault();
            // QSElements.inp_input.trigger("focus");
            QSElements.inp_input.trigger("select");
        }
    });
});

/**
 * Filter the options list by query
 * @param {string} query The query to filter by
 * @param {[{text: string, tags: [{text:string, weight:number}], action: {type: string, target: string}, type: number}]} options The complete options list
 * @returns {[{text: string, tags: [{text:string, weight:number}], action: {type: string, target: string}, type: number}]}
 */
function filterOptions(query, options) {
    if (query.trim() === prevQuery) return prevItems;
    prevQuery = query.trim();

    // const debug = {};

    // Replace not alphanumeric chars in query
    const s_ws_query = query
        .trim()
        .toLowerCase()
        .replace(/\s+/g, " ")
        .replace(/[^A-z0-9 ]/g, ""); // striped query with spaces
    const s_query = s_ws_query.replace(/\s+/g, ""); // striped query without spaces
    const qwCount = s_ws_query.length - s_query.length + 1; // amount of single words in query

    let list = options.map((option) => {
        // Replace not alphanumeric chars in text
        const s_text = option.text.toLowerCase().replace(/[^A-z0-9]/g, "");

        // Calculate display text weight
        // Test text against all single words in query
        const textWeight = s_text.includes(s_query)
            ? s_query.length / s_text.length
            : 0;

        // Calculate tag weight
        const tagWeight = option.tags
            .map((tag) => {
                // Replace not alphanumeric chars in tag name
                const s_tag = tag.text.toLowerCase().replace(/[^A-z0-9]/g, "");

                // Test tag against all single words in query
                let tagWeight = 0;
                s_ws_query.split(" ").forEach((queryWord) => {
                    if (s_tag.includes(queryWord))
                        tagWeight = Math.max(
                            queryWord.length / s_tag.length,
                            tagWeight
                        );
                });

                // return tag weight
                return tagWeight * tag.weight;
            })
            .reduce((prev, cur) => {
                // Add up tag weights
                return prev + cur;
            });

        // Determine term weight
        const termWeight = Math.max(
            textWeight * filter.multiplier.text,
            (tagWeight * filter.multiplier.tags) / qwCount
        );

        // debug[option.text] = {
        //     textWeight,
        //     tagWeight,
        //     termWeight,
        // };

        // Return option with weight
        return {
            ...option,
            weight: termWeight,
        };
    });

    // console.log("\n\nSearch Term: " + query);
    // console.table(debug);

    // Sort option list
    list = list.sort(function (a, b) {
        return b.weight - a.weight;
    });

    // System page search (#)
    if (query.startsWith("#")) {
        // Remove options that are not system pages
        list = list.filter((opt) => opt.type === OptionTypes.SYSTEM);
    }
    // Content page search (+)
    else if (query.startsWith("+")) {
        // Remove options that are not system pages
        list = list.filter((opt) => opt.type === OptionTypes.CONTENT);
    }
    // Action search (>)
    else if (query.startsWith(">")) {
        // Remove options that are not system pages
        list = list.filter((opt) => opt.type === OptionTypes.ACTION);
    }

    // Remove options with low weight
    if (list.length < 1) return [item404];
    while (list[list.length - 1].weight < filter.min_weight) {
        list.pop();
        if (list.length < 1) return [item404];
    }

    // Store items
    prevItems = list;

    // Return filtered list
    return list.slice(0, filter.max_elements);
}

/**
 * Checks if the options list is shown
 * @returns {boolean}
 */
function optionsVisible() {
    const $optionContainer = QSElements.opt_wrapper;
    return $optionContainer.length > 0;
}

/**
 * Display the options list
 * @param {string} query The query value
 */
function displayOptions(query) {
    // create list wrapper
    const $optionContainer = $("<div>").attr("id", opt_wrapper_id);

    // create item elements
    filterOptions(query, qs_opt_list).forEach((res) => {
        const { text, action } = res;

        // create the item element
        const $optionItem = $("<a>").addClass(opt_item_class).text(text);

        // add href for link actions
        if (action.type === "link") {
            $optionItem.attr("href", action.target);
            // Hide option list after click
            $optionItem.on("click", function (clickEvent) {
                hideOptions();
                return true;
            });
        }
        // add js for function actions
        else if (action.type === "function") {
            $optionItem.attr("href", "#");
            $optionItem.on("click", function (clickEvent) {
                clickEvent.preventDefault();
                if (typeof window[action.target] === "function") {
                    window[action.target]();
                }
            });
        }

        // append item to container
        $optionContainer.append($optionItem);
    });

    // append list wrapper to input wrapper
    QSElements.inp_wrapper.append($optionContainer);
}

/**
 * Hide the options list
 */
function hideOptions() {
    QSElements.opt_wrapper.remove();
}

/**
 * Provides all relevant DOM Elements
 */
class QSElements {
    /**
     * The input wrappers JQuery DOM Element
     */
    static get inp_wrapper() {
        return $(`#${inp_wrapper_id}`);
    }
    /**
     * The inputs JQuery DOM Element
     */
    static get inp_input() {
        return $(`#${inp_input_id}`);
    }

    /**
     * The option wrappers JQuery DOM Element
     */
    static get opt_wrapper() {
        return $(`#${opt_wrapper_id}`);
    }
    /**
     * The option items JQuery DOM Elements
     */
    static get opt_items() {
        return $(`.${opt_item_class}`);
    }
}
