import CTableMenuButtons from "../menu/ctable-menu-buttons";

class CTableRows {
    static _selection_changed() {
        CTableMenuButtons.UpdateStatus();
    }

    static All() {
        return $("#contentTable tbody tr[data-resource-id]");
    }

    static Each(callback) {
        this.All().each(callback);
    }

    static SelectAll() {
        this.Each(function () {
            CTableRows.Select(this);
        });
        this._selection_changed();
    }

    static DeselectAll() {
        this.Each(function () {
            CTableRows.Deselect(this);
        });
        this._selection_changed();
    }

    static Select(row) {
        if (!row.addClass) {
            row = $(row);
        }
        row.addClass("selected");
        this._selection_changed();
    }

    static Deselect(row) {
        if (!row.removeClass) {
            row = $(row);
        }
        row.removeClass("selected");
        this._selection_changed();
    }

    static ToggleSelect(row) {
        if (this.IsSelected(row)) this.Deselect(row);
        else this.Select(row);
    }

    static IsSelected(row) {
        if (!row.hasClass) {
            row = $(row);
        }
        return row.hasClass("selected");
    }

    static GetSelectedRows() {
        return $("#contentTable tbody tr.selected");
    }

    static GetSelectedRowsCount() {
        return this.GetSelectedRows().length;
    }
}

export default CTableRows;
