import CTableRows from "../rows/ctable-rows";
import ResourceAction from "./resource-action";

class EditResourceAction extends ResourceAction {
    static Enabled() {
        return CTableRows.GetSelectedRowsCount() === 1;
    }

    static Action(row = false) {
        let id = "";
        if (row) {
            id = $(row).data().resourceId;
        } else {
            id = CTableRows.GetSelectedRows().data().resourceId;
        }

        window.location = $("#contentTable").data().resourcePath + "/" + id;
    }

    static Register() {
        $('[data-resource-action="edit"]').each(function () {
            this.onclick = function (clickEvent) {
                clickEvent.preventDefault();
                if (EditResourceAction.Enabled()) {
                    EditResourceAction.Action();
                }
            };
        });
    }
}

export default EditResourceAction;
