import icons from "./available-icons.json";
import Modal from "./modals/modal";
import ModalContent from "./modals/modal-content";

$(function () {
    $(".icon-select .select-button").on("click", function (clickEvent) {
        clickEvent.preventDefault();
        const $group = $(this).parents(".icon-select");

        const modal = new Modal("Select an icon");

        const iconList = new ModalContent("div", {}, ["modal-icon-list"]);

        const iconsElements = [];
        Object.keys(icons).forEach((group) => {
            icons[group].forEach((icon) => {
                const l = iconsElements.push(
                    new ModalContent("i", {}, [group, `fa-${icon}`])
                );
                iconsElements[l - 1].addListener("click", function () {
                    const iconClass = `${group} fa-${icon}`;

                    // update display and input
                    $group
                        .children(".input")
                        .children(".preview")
                        .children("i")
                        .removeClass()
                        .addClass(iconClass);

                    $group.children(".icon-input").val(iconClass);

                    modal.dispose();
                });
            });
        });

        iconList.addContentNodes(...iconsElements);
        modal.addContentNodes(iconList);

        modal.render("main");
    });
});
