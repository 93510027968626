$(function () {
    $("a[data-reveal-target]").on("click", function (clickEvent) {
        clickEvent.preventDefault();
        if ($(this).hasClass("active")) return;

        const group = $(this).data("revealGroup");
        const links = $(`[data-reveal-group=${group}][data-reveal-target]`);
        const targets = $(`[data-reveal-group=${group}][data-reveal-id]`);
        const targetId = $(this).data("revealTarget");

        links.each(function () {
            $(this).toggleClass(
                "active",
                $(this).data("revealTarget") === targetId
            );
        });

        targets.each(function () {
            $(this).toggleClass("show", $(this).data("revealId") === targetId);
        });
    });
});
