import ResourceAction from "./resource-action";

class CreateResourceAction extends ResourceAction {
    static Enabled() {
        return true;
    }

    static Action() {
        window.location = $("#contentTable").data().resourcePath + "/create";
    }

    static Register() {
        $('[data-resource-action="create"]').each(function () {
            this.onclick = function (clickEvent) {
                clickEvent.preventDefault();
                if (CreateResourceAction.Enabled()) {
                    CreateResourceAction.Action();
                }
            };
        });
    }
}

export default CreateResourceAction;
