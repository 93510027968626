import "./interaction-functions";
import CTableMenuButtons from "./menu/ctable-menu-buttons";
import EditResourceAction from "./resource-actions/edit-resource-action";
import CTableRows from "./rows/ctable-rows";

CTableMenuButtons.UpdateStatus();

// row click functions
function tableRowSingleClickListener(clickEvent) {
    if (clickEvent.ctrlKey) {
        CTableRows.ToggleSelect(this);
    } else {
        const wasSelected = CTableRows.IsSelected(this);
        CTableRows.DeselectAll();
        if (!wasSelected) CTableRows.Select(this);
    }
}

function tableRowDoubleClickListener() {
    EditResourceAction.Action(this);
}

// make rows selectable
CTableRows.Each(function () {
    $(this).on("click", tableRowSingleClickListener);
    $(this).on("dblclick", tableRowDoubleClickListener);
});
