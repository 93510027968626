import ModalButton from "./modal-button";
import ModalContent from "./modal-content";

class Modal {
    // Static part
    static ConfirmPopup(
        title,
        message,
        confirmAction,
        confirmMessage,
        cancelMessage,
        cancelAction = false
    ) {
        const modal = new Modal(title);

        const textNode = new ModalContent("p");
        textNode.setInnerHTML(message);

        const cancelButton = ModalButton.DefaultCancel(() => {
            modal.dispose();
            if (!!cancelAction) cancelAction();
        }, cancelMessage);

        const acceptButton = ModalButton.DefaultAccept(() => {
            confirmAction();
            modal.dispose();
        }, confirmMessage);

        modal.addContentNodes(textNode);
        modal.addButtonNodes(cancelButton, acceptButton);

        modal.render(document.body);
    }

    static InfoPopup(title, message, closeAction = false) {
        const modal = new Modal(title);

        const textNode = new ModalContent("p");
        textNode.setInnerText(message);

        const okButton = ModalButton.DefaultAccept(() => {
            if (!!closeAction) closeAction();
            modal.dispose();
        }, "OK");

        modal.addContentNodes(textNode);
        modal.addButtonNodes(okButton);

        modal.onclose = closeAction;

        modal.render(document.body);

        return modal;
    }

    static SearchPopup(title, fields, action, showAllAction = false) {
        const modal = new Modal(title);

        function acceptAction() {
            const values = {};

            fields.forEach((field) => {
                const id = `modal-${
                    field.type == "autocomplete" ? "input" : field.type
                }-${field.name}`;
                const value = document.getElementById(id).value;
                values[field.name] = value;
            });

            action(values);
            modal.dispose();
        }

        const form = new ModalContent("form");
        form.addListener("submit", (e) => {
            e.preventDefault();
            acceptAction();
        });
        form.addListener("keydown", (e) => {
            if (e.key == "Enter") acceptAction();
        });

        fields.forEach((field) => {
            if (field.type == "input") {
                form.addContentNodes(
                    ModalContent.CreateInputField(field.label, field.name)
                );
            } else if (field.type == "select") {
                form.addContentNodes(
                    ModalContent.CreateSelectField(
                        field.label,
                        field.name,
                        field.options
                    )
                );
            } else if (field.type == "autocomplete") {
                form.addContentNodes(
                    ModalContent.CreateAutoCompleteInputField(
                        field.label,
                        field.name
                    )
                );
            }
        });

        const cancelButton = ModalButton.DefaultCancel(() => modal.dispose());

        const acceptButton = ModalButton.DefaultAccept(acceptAction, "Suchen");

        if (!!showAllAction) {
            const showAll = ModalButton.CreateLink("Alle anzeigen", () => {
                showAllAction();
                modal.dispose();
            });
            showAll.addClass("mr-auto");

            modal.addButtonNodes(showAll);
        }

        modal.addContentNodes(form);
        modal.addButtonNodes(cancelButton, acceptButton);

        modal.render(document.body);
    }

    static UploadPopup(
        title,
        uploadAction,
        {
            uploadText = "upload",
            multiple = false,
            acceptFiles = [],
            asText = true,
        }
    ) {
        let file = null;

        function accept() {
            if (!file) {
                Modal.InfoPopup(
                    "Keine Datei ausgewählt",
                    "Bitte wählen Sie eine CSV-Datei für den Nutzerimport aus."
                );
                return;
            }

            if (asText) {
                // read file
                const reader = new FileReader();
                reader.onload = (event) => {
                    const content = event.target.result;
                    uploadAction(content);
                    modal.dispose();
                };
                reader.readAsText(file);
            } else {
                uploadAction(file);
            }
        }

        const modal = new Modal(title);

        const input = new ModalContent("input", {
            type: "file",
            id: "file-upload",
            multiple,
            accept: !!acceptFiles ? acceptFiles.join(", ") : "",
        });
        input.addListener("change", (e) => (file = event.target.files[0]));

        const cancelButton = ModalButton.DefaultCancel(() => modal.dispose());

        const acceptButton = ModalButton.DefaultAccept(accept, uploadText);

        modal.addContentNodes(input);
        modal.addButtonNodes(cancelButton, acceptButton);

        modal.render(document.body);
    }

    static InputPopup(
        title,
        label,
        name,
        action,
        acceptText,
        value = "",
        type = "text"
    ) {
        const modal = new Modal(title);
        const input = new ModalContent(
            "input",
            {
                type,
                placeholder: label,
                name,
                id: `modal-input-${name}`,
                value,
            },
            ["form-control"]
        );

        const acceptButton = ModalButton.DefaultAccept(() => {
            const value = document.getElementById(`modal-input-${name}`).value;
            modal.dispose();
            action(value);
        }, acceptText);
        const cancelButton = ModalButton.DefaultCancel(() => {
            modal.dispose();
        });

        modal.addContentNodes(input);
        modal.addButtonNodes(cancelButton, acceptButton);

        modal.render(document.body);
    }

    // Class part
    constructor(title) {
        this.title = title;
        this.contentNodes = [];
        this.buttonNodes = [];
        this.modal = null;
        this.attributes = {};
        this.classes = [];
        this.size = "md";
        this.scrollable = false;
        this.onclose = false;
    }

    addContentNodes(...contentNodes) {
        contentNodes.forEach((contentNode) => {
            if (contentNode instanceof ModalContent)
                this.contentNodes.push(contentNode.render());
            else this.contentNodes.push(contentNode);
        });
    }

    addButtonNodes(...buttonNodes) {
        buttonNodes.forEach((buttonNode) => {
            if (buttonNode instanceof ModalButton)
                this.buttonNodes.push(buttonNode.render());
            else this.buttonNodes.push(buttonNode);
        });
    }

    addAttributes(attributes) {
        Object.keys(attributes).forEach((attribute) => {
            this.attributes[attribute] = attributes[attribute];
        });
    }

    addClasses(...classes) {
        classes.forEach((c) => this.classes.push(c));
    }

    setSize(size) {
        this.size = size;
    }

    setScrollable(scrollable) {
        this.scrollable = scrollable;
    }

    render(target = false) {
        // modal
        this.modal = document.createElement("div");
        this.modal.classList.add("modal", ...this.classes);
        this.modal.setAttribute("role", "dialog");
        Object.keys(this.attributes).forEach((attribute) => {
            this.modal.setAttribute(attribute, this.attributes[attribute]);
        });

        // modal dialog
        const modalDialog = document.createElement("div");
        modalDialog.classList.add("modal-dialog", `modal-${this.size}`);
        if (this.scrollable)
            modalDialog.classList.add("modal-dialog-scrollable");
        modalDialog.role = "document";

        // modal content
        const modalContent = document.createElement("div");
        modalContent.classList.add("modal-content");

        // modal header
        const modalHeader = document.createElement("div");
        modalHeader.classList.add("modal-header");

        // modal title
        const modalTitle = document.createElement("h5");
        modalTitle.classList.add("modal-title");
        modalTitle.innerHTML = this.title;

        // modal close
        const modalClose = document.createElement("button");
        modalClose.classList.add("close");
        modalClose.type = "button";
        modalClose.setAttribute("data-dismiss", "modal");
        modalClose.setAttribute("aria-label", "Close");
        modalClose.onclick = () => {
            this.dispose();
        };

        // modal close content
        const modalCloseContent = document.createElement("span");
        modalCloseContent.setAttribute("aria-hidden", true);
        modalCloseContent.innerHTML = "&times;";

        // modal body
        const modalBody = document.createElement("div");
        modalBody.classList = "modal-body";

        // modal footer
        const modalFooter = document.createElement("div");
        modalFooter.classList.add("modal-footer");

        // combine nodes
        modalHeader.append(modalTitle);
        modalClose.append(modalCloseContent);
        modalHeader.append(modalClose);

        // append content and button nodes
        modalBody.append(...this.contentNodes);
        modalFooter.append(...this.buttonNodes);

        // add to wrapper elements
        modalContent.append(modalHeader, modalBody, modalFooter);
        modalDialog.append(modalContent);
        this.modal.append(modalDialog);

        if (target) {
            if (typeof target == "string") {
                document.querySelector(target).append(this.modal);
            } else {
                target.append(this.modal);
            }
        } else {
            return this.modal;
        }
    }

    dispose() {
        if (this.onclose) this.onclose();
        if (this.modal !== null) this.modal.outerHTML = "";
    }
}

export default Modal;
