import CreateResourceAction from "../resource-actions/create-resource-action";
import DeleteResourceAction from "../resource-actions/delete-resource-action";
import EditResourceAction from "../resource-actions/edit-resource-action";

class CTableMenuButtons {
    static _init() {
        this.resourceActions = {
            create: CreateResourceAction,
            edit: EditResourceAction,
            delete: DeleteResourceAction,
        };

        CreateResourceAction.Register();
        EditResourceAction.Register();
        DeleteResourceAction.Register();

        this._is_initilized = true;
    }

    static _autoinit() {
        if (!this._is_initilized) this._init();
    }

    static Enable(element) {
        this._autoinit();
        if (!element.removeClass) {
            element = $(element);
        }
        element.removeClass("disabled");
    }

    static Disable(element) {
        this._autoinit();
        if (!element.addClass) {
            element = $(element);
        }
        element.addClass("disabled");
    }

    static All() {
        this._autoinit();
        return $("#contentTableMenu [data-resource-action]");
    }

    static Each(callback) {
        this._autoinit();
        this.All().each(callback);
    }

    static UpdateStatus() {
        this._autoinit();
        // general settings

        // adjust content table menu
        CTableMenuButtons.Each(function () {
            const action =
                CTableMenuButtons.resourceActions[this.dataset.resourceAction];
            if (action.Enabled()) {
                CTableMenuButtons.Enable(this);
            } else {
                CTableMenuButtons.Disable(this);
            }
        });
    }
}

export default CTableMenuButtons;
